/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import minPS from "../../../../images/Algorithms/minimum-path-sum.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    h3: "h3",
    ol: "ol",
    li: "li",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "minimum-path-sum",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#minimum-path-sum",
    "aria-label": "minimum path sum permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Minimum Path Sum"), "\n", React.createElement(_components.p, null, "and Dynamic Programming Solution to it using memoization and tabulation in Rust Language."), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "Minimum Path Sum, also known as Minimum Cost Path, is a grid based Dynamic Programming problem.\nIn this problem, you are given a grid of positive numbers, and you have to tell the minimum sum of elements from top left corner to bottom right through any path,\nbut you can move only rightwards or downwards."), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: minPS,
    width: "100%",
    alt: "Minimum Path Sum Grid"
  })), "\n", React.createElement(_components.p, null, "So, for above grid, answer is ", React.createElement(_components.strong, null, "22")), "\n", React.createElement(_components.h2, {
    id: "recursive-solution",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#recursive-solution",
    "aria-label": "recursive solution permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Recursive Solution"), "\n", React.createElement(_components.p, null, "In recursive solution, we start from the end, that is, bottom right cell, and take minimum of Minimum Path Sum of the cell upwards and leftwards to it."), "\n", React.createElement(_components.p, null, "So, we find the Minimum Path Sum of upward and leftward cell and return this after adding the cost of current cell."), "\n", React.createElement(_components.h3, {
    id: "algorithm",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm",
    "aria-label": "algorithm permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Base Case would be that, if the current grid has 1 column and 1 row, return value of current cell."), "\n", React.createElement(_components.li, null, "If current Grid has 1 row, we can traverse only leftwards. So, return cost of current cell + cost of Minimum Path Sum of the left cell."), "\n", React.createElement(_components.li, null, "Similarly, if the grid has only 1 column, return cost of current cell + cost of Minimum Path Sum of the upward cell."), "\n", React.createElement(_components.li, null, "Else, return the cost of current cell + minimum of Minimum Path Sum of the upward cell and leftward cell."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function",
    "aria-label": "function permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize) -> usize {\n\n    // Base Case, if only 1 row and column, return the value of top left cell\n    if r == 1 && c ==1 { return grid[0][0]; }\n\n    // If only 1 row, we can only move leftwards\n    if r==1 { return grid[r-1][c-1] + minimum_cost_path(grid,r, c-1 ) ;}\n\n    // If only 1 column, we can only move upwards\n    if c==1 { return grid[r-1][c-1] + minimum_cost_path(grid,r-1, c ); }\n\n    // Else, we take minimum of both leftwards path and rightwards path\n    return grid[r-1][c-1] + min(minimum_cost_path(grid,r-1, c ) , minimum_cost_path(grid,r, c-1 ));\n}\n")), "\n", React.createElement(_components.p, null, "With Driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize) -> usize {\n\n    // Base Case, if only 1 row and column, return the value of top left cell\n    if r == 1 && c ==1 { return grid[0][0]; }\n\n    // If only 1 row, we can only move leftwards\n    if r==1 { return grid[r-1][c-1] + minimum_cost_path(grid,r, c-1 ) ;}\n\n    // If only 1 column, we can only move upwards\n    if c==1 { return grid[r-1][c-1] + minimum_cost_path(grid,r-1, c ); }\n\n    // Else, we take minimum of both leftwards path and rightwards path\n    return grid[r-1][c-1] + min(minimum_cost_path(grid,r-1, c ) , minimum_cost_path(grid,r, c-1 ));\n}\n\n// Driver Code\n\nfn main() {\n    let grid = vec![\n        vec![2, 4, 1, 5, 6],\n        vec![3, 3, 2, 6, 7],\n        vec![1, 4, 5, 3, 5],\n    ];\n    println!(\"{}\", minimum_cost_path(&grid, grid.len(), grid[0].len()));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "22"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( 2", React.createElement("sup", null, "r+c"), " )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( r+c )")), "\n", React.createElement(_components.p, null, "( Space complexity includes recursive stack space )"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " Space complexity is 2", React.createElement("sup", null, "r+c"), "  because each time, we have 2 choices."), "\n", React.createElement(_components.h2, {
    id: "overlapping-sub-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overlapping-sub-problems",
    "aria-label": "overlapping sub problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overlapping Sub-problems"), "\n", React.createElement(_components.p, null, "If we have a look carefully on recursive approach, we computed multiple results many times."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For example :"), " In a 20×20 grid, you can reach the (10, 10) cell in thousands of ways, and takes thousands of recursions each time to calculate."), "\n", React.createElement(_components.p, null, "These are called ", React.createElement(_components.strong, null, "Overlapping Sub-problems"), " because they are smaller part of large problems, and are computed again and again."), "\n", React.createElement(_components.p, null, "So, we simply calculate them once, and store it in a matrix, and retrieve it when necessary. This helps to save a lot of computation."), "\n", React.createElement(_components.p, null, "This is called Dynamic Programming Approach for the problem."), "\n", React.createElement(_components.h2, {
    id: "memoization--top-down-dp--method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#memoization--top-down-dp--method",
    "aria-label": "memoization  top down dp  method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Memoization ( Top-down DP ) Method"), "\n", React.createElement(_components.p, null, "In memoization method, we simply take a DP matrix, and store the computed result."), "\n", React.createElement(_components.h3, {
    id: "algorithm-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm-1",
    "aria-label": "algorithm 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Initially, take a DP matrix and set all its elements to ", React.createElement(_components.code, null, "None"), " type. Alternatively, you can set it to -1."), "\n", React.createElement(_components.li, null, "If the minimum path sum is already calculated, that is given index of matrix is ", React.createElement(_components.code, null, "Some"), " or not -1, return it."), "\n", React.createElement(_components.li, null, "Else, calculate the minimum path sum by using recursion and store it in the DP matrix."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-1",
    "aria-label": "function 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize, dp:&mut Vec<Vec<Option<usize>>>) -> usize {\n\n    // If Already computed, return it\n    if dp[r-1][c-1].is_some() { return dp[r-1][c-1].unwrap(); }\n\n    // Base Case, if only 1 row and column, return the value of top left cell\n    if r == 1 && c ==1 { dp[0][0] = Option::from(grid[0][0]) ;return dp[0][0].unwrap();  }\n\n    // If only 1 row, we can only move leftwards\n    if r==1 { dp[0][c-1] = Option::from(grid[0][c-1] + minimum_cost_path(grid,1, c-1 , dp)) ; return dp[0][c-1].unwrap();}\n\n    // If only 1 column, we can only move upwards\n    if c==1 { dp[r-1][0] = Option::from(grid[r-1][0] + minimum_cost_path(grid,r-1, 1 , dp)) ; return dp[r-1][0].unwrap();}\n\n    // Else, we take minimum of both leftwards path and rightwards path\n    dp[r-1][c-1] = Option::from( grid[r-1][c-1] + min(minimum_cost_path(grid,r-1, c, dp ) , minimum_cost_path(grid,r, c-1, dp)) );\n\n    return dp[r-1][c-1].unwrap();\n}\n")), "\n", React.createElement(_components.p, null, "With Driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize, dp:&mut Vec<Vec<Option<usize>>>) -> usize {\n\n    // If Already computed, return it\n    if dp[r-1][c-1].is_some() { return dp[r-1][c-1].unwrap(); }\n\n    // Base Case, if only 1 row and column, return the value of top left cell\n    if r == 1 && c ==1 { dp[0][0] = Option::from(grid[0][0]) ;return dp[0][0].unwrap();  }\n\n    // If only 1 row, we can only move leftwards\n    if r==1 { dp[0][c-1] = Option::from(grid[0][c-1] + minimum_cost_path(grid,1, c-1 , dp)) ; return dp[0][c-1].unwrap();}\n\n    // If only 1 column, we can only move upwards\n    if c==1 { dp[r-1][0] = Option::from(grid[r-1][0] + minimum_cost_path(grid,r-1, 1 , dp)) ; return dp[r-1][0].unwrap();}\n\n    // Else, we take minimum of both leftwards path and rightwards path\n    dp[r-1][c-1] = Option::from( grid[r-1][c-1] + min(minimum_cost_path(grid,r-1, c, dp ) , minimum_cost_path(grid,r, c-1, dp)) );\n\n    return dp[r-1][c-1].unwrap();\n}\n\n// Driver Code\n\nfn main() {\n    let grid = vec![\n        vec![2, 4, 1, 5, 6],\n        vec![3, 3, 2, 6, 7],\n        vec![1, 4, 5, 3, 5],\n    ];\n\n    // Initialize DP matrix\n    let mut dp = vec![vec![Option::None; grid[0].len()]; grid.len()];\n\n    println!(\"{}\", minimum_cost_path(&grid, grid.len(), grid[0].len(), &mut dp));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "22"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( r*c )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( r*c )")), "\n", React.createElement(_components.h2, {
    id: "tabulation---bottom-up-dp---method",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tabulation---bottom-up-dp---method",
    "aria-label": "tabulation   bottom up dp   method permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tabulation  ( Bottom-up DP )  Method"), "\n", React.createElement(_components.p, null, "Although time and space complexities of tabulation as well as memoization method are same, tabulation is much more efficient as there are a lot of expensive recursive calls in memoization."), "\n", React.createElement(_components.p, null, "In tabulation method, we make the DP matrix, and fill it first on the basis of base condition, and then on the basis of previous row."), "\n", React.createElement(_components.h3, {
    id: "algorithm-2",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#algorithm-2",
    "aria-label": "algorithm 2 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Algorithm"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Initialize the first row and first column like ", React.createElement(_components.a, {
    href: "/array-algorithms/prefix-sum-array/"
  }, "Prefix Sum Array"), ", because to reach to given cell in first row or column, we have only 1 path, rightwards in case of row and downwards in case of column."), "\n", React.createElement(_components.li, null, "For all cells in DP matrix, set its value to the sum of its corresponding value in initial grid and minimum of adjacent left and up cell."), "\n", React.createElement(_components.li, null, "Finally, return the bottom right value of the DP matrix."), "\n"), "\n", React.createElement(_components.h3, {
    id: "function-2",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#function-2",
    "aria-label": "function 2 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Function"), "\n", React.createElement(_components.p, null, "Here is the function using above algorithm"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize, dp:&mut Vec<Vec<Option<usize>>>) -> usize {\n\n    // Set dp[0][0], the first element of DP matrix as corresponding grid value.\n    dp[0][0] = Option::from(grid[0][0]);\n\n    // Initialize the first row and column of the DP matrix\n    for i in 1..r { dp[i][0] = Option::from(dp[i-1][0].unwrap() + grid[i][0]); }\n    for i in 1..c { dp[0][i] = Option::from(dp[0][i-1].unwrap() + grid[0][i]); }\n\n    // Traverse for each Row and column\n    for i in 1..r {\n        for j in 1..c {\n            // Set dp[i][j] as sum of corresponding grid value and\n            // Minimum of minimum path sum of upper and left cell\n            dp[i][j] = Option::from(grid[i][j] + min(dp[i-1][j].unwrap(), dp[i][j-1].unwrap()));\n        }\n    }\n\n    // Finally, Return last element\n    return dp[r-1][c-1].unwrap();\n}\n")), "\n", React.createElement(_components.p, null, "Use the same driver code."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "22"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( r*c )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( r*c )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Minimum Path Sum, also known as Minimum Cost Path, is a grid based Dynamic Programming problem."), "\n", React.createElement(_components.p, null, "In this problem, you are given a grid of positive numbers, and you have to tell the minimum sum of elements from top left corner to bottom right through any path,\nbut you can move only rightwards or downwards."), "\n", React.createElement(_components.p, null, "In this article, we saw how to solve the Minimum Path Sum problem, first using recursion and then using Dynamic Programming, memoization as well as tabulation method in Rust Language."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::cmp::min;\n\npub fn minimum_cost_path(grid: &Vec<Vec<usize>>, r:usize, c:usize, dp:&mut Vec<Vec<Option<usize>>>) -> usize {\n    dp[0][0] = Option::from(grid[0][0]);\n    for i in 1..r { dp[i][0] = Option::from(dp[i-1][0].unwrap() + grid[i][0]); }\n    for i in 1..c { dp[0][i] = Option::from(dp[0][i-1].unwrap() + grid[0][i]); }\n    for i in 1..r {\n        for j in 1..c {\n            dp[i][j] = Option::from(grid[i][j] + min(dp[i-1][j].unwrap(), dp[i][j-1].unwrap())); }}\n    return dp[r-1][c-1].unwrap();\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: " Minimum Path Sum using Recursion, Memoization and Tabulation - Dynamic Programming - Rust Programming",
    description: "Tell the minimum sum of elements from top left corner to bottom right in the given grid. We will see recursive, memoization and tabulation DP solution in Rust Language."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
